import { Loader } from "@googlemaps/js-api-loader";

document.addEventListener("DOMContentLoaded", () => {
  const blocks = document.querySelectorAll(".block-gmap");
  [...blocks].forEach((item) => {
    const mapDiv = item.querySelector("#map");
    if (!mapDiv) {
      return;
    }
    const keyElement = item.querySelector("#hide-map");
    const key = keyElement.getAttribute("data-map");
    const rawLat = keyElement.getAttribute("data-lat");
    const rawLong = keyElement.getAttribute("data-long");
    const lat = parseFloat(rawLat);
    const long = parseFloat(rawLong);
    const loader = new Loader({
      apiKey: key,
      version: "weekly",
      librairies: ["marker"],
    });
    loader.load().then(() => {
      const map = new google.maps.Map(mapDiv, { // eslint-disable-line
        center: { lat, lng: long },
        zoom: 15,
      });
      const marker = new google.maps.Marker({ // eslint-disable-line
        position: { lat, lng: long },
        // icon: {
        //   path: google.maps.SymbolPath, // eslint-disable-line
        //   scale: 10,
        //   strokeColor: "#656C5F",
        // },
        title: "fdflex",
      });
      marker.setMap(map);
    });
  });
});
